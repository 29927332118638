.App {
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* home page */
.col-left,
.col-right {
  border: 1px solid #ccc;
}

.container-custom {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  margin-top: 50px;
}

.container-custom-big {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  margin-top: 50px;
}

.center {
  text-align: center;
}

/* email register page */
.email-register button,
.email-change button,
.register-confirm button,
.password-reissue button,
.profile-change-confirm button {
  border: 1px solid;
  background-color: #fff;
}

.box-title {
  font-size: 24px;
  border-bottom: 1px solid;
  padding: 0 15px;
}

.box-register .box-body {
  padding: 15px 50px 0 50px;
}

.box-body {
  padding: 15px 15px 0 15px;
}

.btn-primary {
  padding: 0 7px;
  height: 25px !important;
  border-color: #434343;
}

.btn-secondary {
  padding: 0 5px;
  height: 20px !important;
  border-color: #434343;
  line-height: 1;
  border: 1px solid;
}

.btn-default {
  padding: 0 45px;
}

.txt-primary {
  border-color: #434343;
  height: 25px;
}

.txt-email {
  max-width: 200px;
}

.modal-primary .ant-form-item {
  margin-bottom: 0;
}

.modal-primary .description {
  margin-bottom: 20px;
}

.col-lbl {
  padding-top: 5px;
}

.link {
  color: #434343;
  display: inline-block;
}

.box-register-completion .box-body {
  margin-bottom: 100px;
}

/* header */
.img-user-thumbail {
  max-width: 50px;
}

.header {
  padding: 5px 20px;
  border-bottom: 1px solid;
}

.btn-language {
  padding: 0px 30px 0px 5px;
  height: 25px;
  border-color: #434343;
}

.drop-user {
  min-width: 210px;
}

.drop-user .ant-list-item-meta-description {
  font-size: 10px;
}

.drop-user .ant-dropdown-menu-item {
  padding: 2px 5px;
}

.drop-user .ant-list-item-meta-avatar {
  margin-right: 5px;
}

.drop-user .ant-dropdown-menu-title-content {
  font-size: 12px;
}

.drop-user .ant-list-item {
  padding: 0;
}

.drop-user .ant-dropdown-menu-item:not(:first-child) {
  padding-left: 15px;
}

.drop-user .ant-list-item-meta-title {
  font-size: 12px;
  margin-bottom: 0;
}

/* register page */
.box-register-confirm {
  margin-bottom: 30px;
}

.box-register {
  border: 1px solid;
}

.required,
.error,
.message-error,
.color-red,
.changed {
  color: red;
}

.box .ant-form-item {
  margin-bottom: 10px;
}

.lbl-policy {
  font-size: 12px !important;
}

.box {
  max-width: 715px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  margin-top: 50px;
}

.policy-content {
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid;
  padding: 10px;
  margin-top: 20px;
}

.btn-add-search {
  margin-left: 10px;
  margin-top: 3px;
}

/* common */
button {
  color: #000;
}

button[disabled] {
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.25);
}

.cursor-pointer {
  cursor: pointer;
}

.m-auto {
  margin: auto;
}

.height20 {
  height: 20px;
}

.border-none {
  border: none !important;
}

.d-flex {
  display: flex;
}

.m-auto {
  margin: auto;
}

.bg-info {
  background: rgb(246, 249, 252);
  background: -moz-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  background: linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f9fc", endColorstr="#f6f9fc", GradientType=1);
}

.btn-info {
  height: 20px;
  line-height: 1;
  border-radius: 3px;
  padding: 0 5px;
}

.btn-submit,
.btn-return,
.btn-cancel {
  height: 20px;
  padding: 0 23px;
  line-height: 20px !important;
  border: 1px solid;
  border-radius: 3px;
  background: rgb(246, 249, 252);
  background: -moz-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  background: linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f9fc", endColorstr="#f6f9fc", GradientType=1);
}

.ant-breadcrumb>span:last-child a {
  color: rgba(0, 0, 0, 0.45);
}

.full-width {
  width: 100%;
}

.vertical-align-top {
  vertical-align: top;
}

.hover-underline:hover {
  text-decoration: underline;
}

a {
  color: #000;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.ml-primary {
  margin-left: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.p-10 {
  padding: 10px;
}

.p-5 {
  padding: 5px;
}

.mb-primary {
  margin-bottom: 15px;
}

.mb-secondary {
  margin-bottom: 5px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.border-default {
  border: 1px solid;
}

.border-bottom-primary {
  border-bottom: 1px dashed;
}

.border-bottom-default {
  border-bottom: 1px solid;
}

.border-top-default {
  border-top: 1px solid;
}

.border-bottom-secondary {
  border-bottom: 1px solid #a6a6a6;
}

.border-right-default {
  border-right: 1px solid #d3d3d3;
}

.border-right-primary {
  border-right: 1px solid #000 !important;
}

.my-border-bottom-primary {
  border-bottom: 1px solid #3333ff;
}

.bb-0 {
  border-bottom: 0 !important;
}

.f-small {
  font-size: 12px;
}

.f-medium {
  font-size: 16px;
}

.bg-primary {
  background-color: rgb(204, 255, 255);
}

.bg-secondary {
  background-color: rgb(235, 241, 222);
}

.bg-dancer {
  background-color: rgb(252, 213, 180);
}

.bg-gray {
  background-color: rgb(242, 242, 242) !important;
}

.bg-alert {
  background-color: rgb(253, 233, 217);
}

.white-space {
  padding: 25px 0;
}

.color-primary {
  color: #e26b0a;
}

.color-secondary {
  color: #3333ff !important;
}

.f20 {
  font-size: 20px;
}

.report-title {
  font-size: 24px;
}

.ant-input-number-input {
  height: 20px;
}

.loading::after {
  /* content: url('./assets/images/loading.gif'); */
  content: '';
  background-image: url('./assets/images/loading.gif');
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  z-index: 99999999999999;
}

.loading::before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.45);
}

.line-primary {
  height: 1px;
  background-color: #3333ff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.line-default {
  height: 1px;
  background-color: #434343;
  margin-top: 15px;
  margin-bottom: 15px;
}

.vertical-align-center {
  display: flex;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-dancer {
  background: rgb(246, 249, 252);
  background: -moz-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  background: linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f9fc", endColorstr="#f6f9fc", GradientType=1);
  border: 1px solid;
  border-radius: 4px;
  width: 240px;
  display: inline-block;
  text-align: center;
  height: 20px;
  padding: 0;
  line-height: 20px !important;
}

.btn-dancer2 {
  background: rgb(246, 249, 252);
  background: -moz-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(202, 217, 235, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(202, 217, 235, 1) 100%);
  background: linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(202, 217, 235, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f9fc", endColorstr="#cad9eb", GradientType=1);
}

.button-block .btn-dancer {
  width: 130px;
}

.bold {
  font-weight: bold !important;
}

.label-after-hidden label::after {
  display: none;
}

.label-after-hidden label {
  margin-right: 5px;
}

/* page register confirm */
.txtShowPass {
  padding-left: 0;
}

.txtShowPass .ant-input-suffix {
  display: none;
}

/* page profile */
.title-profile {
  padding-top: 10px;
  padding-bottom: 10px;
}

.panel-setting {
  border: 1px solid;
}

.panel-setting .ant-collapse-borderless {
  background-color: #fff;
}



/*mypage page*/
.box-plan-info .ant-form-item-control-input {
  min-height: unset;
}

.plan-change-application-confirm .base-infomation .ant-form-item-label>label::after {
  display: none;
}

.free-simulation .ant-form-item-control-input {
  min-height: unset;
}

.usage-plan .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
}

.td-store .ant-form-item-label {
  margin-right: 15px;
}

.ant-form-item-explain-error {
  color: #ff4d4f;
}

.system-usage-time .ant-form-item-explain-error {
  position: absolute;
  margin-top: -11px;
  font-size: 11px;
  left: 15%;
}

.col-select {
  width: 17%;
}

.option-change-input .lbl-required::after,
.plan-change-application-confirm .lbl-required::after {
  display: none;
}

.option .ant-form-item-label>label::after {
  display: none;
}

.option .ant-form-item-label>label {
  margin-right: 15px;
}

.btn-return {
  line-height: 20px !important;
}

.list-server .ant-form-item-control-input {
  min-height: auto;
}

.list-server .ant-form-item-label>label {
  margin-right: 20px;
  height: auto;
}

.link-newPlan,
.btn-add {
  background: rgb(246, 249, 252);
  background: -moz-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(202, 217, 235, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(202, 217, 235, 1) 100%);
  background: linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(202, 217, 235, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f9fc", endColorstr="#cad9eb", GradientType=1);
  border: 1px solid;
  border-radius: 3px;
  padding: 0 5px;
}

.link-newPlan {
  display: inline-block;
  margin-top: 20px;
  height: 20px;
  line-height: 20px;
}

.col-list {
  padding: 9px 12px;
  margin-right: 30px;
}

.box-plan,
.container-custom-primary {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
}

.container-custom-secondary {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

.col-display .ant-select {
  width: 100%;
}

.col-display {
  width: 16.5%;
}

.box-plan .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 20px;
}

.box-plan .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 20px;
}

.list-row-server .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-width: 140px;
}

.box-plan .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #434343;
}

.box-plan .ant-table-thead>tr>th[colspan]:not([colspan='1']) {
  text-align: left;
}

.btn-detail,
.mp-btn-primary {
  background-image: linear-gradient(to bottom right, rgba(182, 202, 227, .6), rgba(223, 232, 243, .6));
  padding: 2px 5px;
  border-radius: 4px;
  border: 1px solid;
}

.plan-list table {
  border: 1px solid;
}

.plan-list .ant-table-tbody>tr:not(:last-child)>td {
  border-bottom-color: #434343;
}

.isRequired .ant-form-item-label>label::after,
.lbl-required::after,
.mark-changed::after {
  content: '*';
  margin-left: 4px;
  color: #ff4d4f;
  font-weight: 900;
}

.box-plan .ant-form-item-label>label::before {
  display: none !important;
}

/*planNew page*/
.title {
  border-bottom: 1px solid #3333ff;
}

.base-infomation .ant-form-item-label {
  background-color: rgb(204, 255, 255);
  text-align: left;
}

.base-infomation .ant-form-item:not(:last-child) {
  border-bottom: 1px solid;
}

.base-infomation .ant-form-item {
  margin-bottom: 0;
}

.base-infomation .ant-form-item-control {
  padding: 5px 15px;
}

.base-infomation .ant-form-item-label {
  padding-left: 10px;
  border-right: 1px solid #d3d3d3;
}

.title-sub {
  border-bottom: 1px solid #e26b0a;
  margin-bottom: 20px;
  font-weight: bold;
}

#frmPlanNew input,
#frmPlanNew .ant-picker,
.plan-change .ant-picker {
  border-color: #434343;
  height: 20px;
}

.product-item {
  box-shadow: rgb(0 0 0 / 0%) 0px 19px 18px, rgb(0 0 0 / 3%) 0px 15px 12px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
  min-width: 149px;
  margin: 10px;
}

.bg-gradient1 {
  background: rgb(255, 255, 255);
  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(189, 215, 238, 1) 100%);
}

.bg-gradient2 {
  background: rgb(255, 255, 255);
  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(157, 195, 230, 1) 100%);
}

.bg-gradient3 {
  background: rgb(255, 255, 255);
  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(46, 117, 182, 1) 100%);
}

.bg-gradient4 {
  background: rgb(255, 255, 255);
  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(31, 78, 121, 1) 100%);
}

.pro-title {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.list-geminiot {
  margin-bottom: 30px;
}

.col-label {
  border-right: 1px solid #d3d3d3;
  padding: 5px;
}

.col-list-product {
  padding: 15px;
}

.icon-help {
  color: #3333ff;
  margin-right: 15px;
  font-weight: bold;
}

.usage-plan .ant-collapse-borderless {
  background-color: #fff;
}

.usage-plan .ant-collapse-borderless>.ant-collapse-item {
  border-bottom: 0;
}

.usage-plan .ant-collapse-content>.ant-collapse-content-box {
  padding: 0 !important;
}

.usage-plan .ant-form-item {
  margin-bottom: 0;
}

.list-row-server .row-item {
  align-items: center;
}

.list-row-server .ant-form-item-label>label {
  height: 20px;
}

.row-item:not(:last-child) {
  border-bottom: 1px solid;
}

.row-item-design:not(:last-child) {
  margin-bottom: 5px;
}

.row-item-design .ant-picker {
  max-width: 100px;
  padding: 0;
}

.number-server input {
  width: 60px;
  text-align: center;
}

.list-row-server .number-server .ant-input-number {
  width: 60px;
}

.before-open-brackets::before {
  content: '(';
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 5px;
}

.col-leftBracket,
.col-rightBracket {
  min-width: 20px;
  text-align: center;
}

.option-body {
  border: 1px solid;
}

.option {
  margin-bottom: 30px;
}

.col-control {
  padding: 5px 15px;
}

.system-usage-time,
.campaign {
  margin-bottom: 30px;
}

.page-title {
  color: #3333ff;
  font-weight: bold;
}

#frmPlanNew .ant-form-item-control-input {
  min-height: 20px;
}

.option-body .ant-form-item-label>label {
  height: 20px;
}

.col-setting {
  padding: 5px 15px;
}

.txt-company-name {
  max-width: 300px;
}

.start-time .col-title,
.stop-time .col-title {
  margin-top: -22px;
}

.box-dayofweek:not(.scr-confirm) .start-time,
.box-dayofweek:not(.scr-confirm) .stop-time {
  width: 80px;
}

.box-dayofweek .scr-confirm .start-time,
.box-dayofweek .scr-confirm .stop-time {
  width: 40px;
}

.product-item-selected-changed {
  position: relative;
}

.product-item-selected-changed::before {
  position: absolute;
  content: '*';
  color: red;
  top: -15px;
  right: -5px;
}

.product-item-selected {
  background-color: rgb(102, 255, 255);
}


.content-changed,
.content-changed:not(.ant-form-item-label),
.content-changed .ant-form-item-control-input-content {
  color: red !important;
}

.number-primary {
  font-size: 26px;
  font-weight: bold;
}

.number-secondary {
  font-size: 14px;
  font-weight: bold;
}

.title-secondary {
  font-weight: bold;
  border-bottom: 1px solid;
}

.list-server .ant-form-item {
  margin-bottom: 0;
}

.box-simulation {
  padding: 15px;
}

.plan-list .ant-table-thead>tr>th {
  border-bottom-color: #434343;
  background-color: rgb(204, 255, 255);
  padding: 5px;
}

.plan-list .ant-table-tbody>tr>td {
  background-color: rgb(242, 242, 242);
}

.plan-list .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.usage-plan .ant-collapse-header {
  padding-left: 0 !important;
}

/**planDetail*/
.plan-detail .lbl-required::after {
  display: none;
}

.plan-detail .option-body .ant-form-item-label>label {
  height: 20px;
  margin-right: 15px;
}

.plan-detail .option .ant-form-item-label>label::after {
  display: none;
}

.table-secondary {
  width: 90%;
}

.plan-detail .ant-form-item-control-input {
  min-height: auto;
}

.col-stop-date {
  padding-left: 0 !important;
}

.campain-list .ant-table-thead>tr>th {
  background-color: rgb(204, 255, 255);
  border-bottom-color: #434343;
}

.campain-list .ant-table-tbody>tr>td {
  border-bottom: 0;
}

/**quote make*/
.text-underline {
  text-decoration: underline;
}

/*plan delete*/
.modal-delete .des {
  font-size: 11px;
}

.modal-delete .ant-modal-footer button:first-child {
  float: right;
  margin-right: 40px;
}

.modal-delete .ant-modal-header {
  padding: 0;
}

.modal-delete .ant-modal-footer {
  border-top: 0;
  text-align: center;
}

.modal-delete .ant-modal-content {
  min-height: 182px;
  max-width: 363px;
}

.modal-delete button {
  border: 0;
  background: rgb(246, 249, 252);
  background: linear-gradient(45deg, rgba(246, 249, 252, 1) 0%, rgba(176, 198, 225, 1) 74%, rgba(176, 198, 225, 1) 83%, rgba(246, 249, 252, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f9fc", endColorstr="#f6f9fc", GradientType=1);
}

.modal-delete .ant-btn:hover {
  background: #b9cce4;
  color: #434343;
}

.modal-delete .ant-btn-primary {
  color: #434343;
}


/** page setting start date */
.current {
  color: #434343 !important;
}

.block-setting-start-date .ant-form-item-control-input,
.container-custom-primary .ant-form-item-control-input {
  min-height: auto;
}

.terms-of-service {
  max-height: 200px;
  overflow-y: scroll;
  padding: 10px;
}

.block-setting-start-date .ant-picker {
  height: 20px;
}

.col-date {
  display: flex;
  align-items: end;
}

/*application completion page*/
.campain-list-retailers .ant-table-thead>tr>th {
  background-color: rgb(235, 241, 222);
}

.campain-list-retailers .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.campain-list .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.list-row-server .ant-form-item {
  margin-bottom: 0;
}

.plan-change-application-confirm .product-item-selected {
  position: relative;
}

/* .plan-change-application-confirm .product-item-selected::after {
  content: '*';
  color: red;
  position: absolute;
  top: 0;
} */

.table-parent {
  width: 100%;
}

.table-parent>tbody>tr>td {
  border-bottom: 1px solid;
  padding: 5px;
}

.table-parent th {
  text-align: left;
}

.table-child>tr>td {
  padding-right: 30px;
}

/** page mypage admin*/
.table-confirm-add .border-style1 {
  border-bottom: 1px dotted !important;
  border-right: 1px solid;
  padding-left: 5px;
}

.td-remove {
  width: 13%;
}

.td-delete-account {
  width: 39.3%;
}

.add-account {
  width: 45%;
}

.td-account {
  width: 35%;
}

.table-account,
.table-primary {
  width: 100%;
}

.table-account th,
.table-primary {
  text-align: left;
}

.table-account td,
.table-account th,
.table-primary td,
.table-primary th {
  border: 1px solid;
  padding: 0 5px;
}

.table-delete {
  width: 69%;
}

.table-delete input {
  height: 20px;
}

.table-delete td,
.table-delete th {
  padding: 0 5px;
}

.table-delete th,
.table-delete td {
  padding: 6px 20px;
}

.table-delete .col-input {
  width: 60%;
}

/** page other*/
.title-primary {
  border-bottom: 1px solid #3333ff;
  margin-bottom: 20px;
  font-weight: bold;
}

.date .ant-picker {
  height: 22px;
}

.btn-dancer.size-standard {
  padding: 0;
  height: 22px;
  line-height: 22px;
}

.table-stop {
  width: 100%;
}

.table-stop td {
  padding: 10px;
  vertical-align: top;
}

.table-stop tr {
  border: 1px solid;
}

.box-notify {
  background-color: #f2f2f2;
  padding-top: 10px;
}

.tr-notify {
  background-color: #f2f2f2;
}

.suspension-input .col-label-lv1 {
  width: 190px;
}

.td-label {
  width: 20%;
}

.mark {
  color: red;
  font-weight: 900;
}

.list-row-server .col-label {
  width: 15.5%;
}

.list-row-server .ant-input-number {
  border-color: #434343;
  height: 20px;
  line-height: 20px;
  margin-right: 3px;
}

/** plan confirm page */
.scr-confirm {
  margin-left: 35px;
}

.box-dayofweek .start-time,
.list-row-new .start-time {
  min-width: 35px;
}

.list-row-new {
  margin-left: 25px;
}

.quocte-make .list-server .ant-form-item-label {
  margin-right: 15px;
}

.quocte-make .day-of-week::after,
.plan-detail .day-of-week::after,
.free-simulation .day-of-week::after,
.change-application-feeSimulation .day-of-week::after {
  content: ':';
  margin-left: 15px;
}

.box-dayofweek .ant-form-item {
  margin-bottom: 0;
}

.plan-confirm .list-row-server {
  margin-left: 15px;
}

.plan-confirm .option label {
  margin-right: 15px;
}

.col-list-product .img-logo,
.free-simulation .img-logo,
.application-confirm .img-logo,
.change-application-confirm .img-logo,
.plan-change-application-confirm .img-logo,
.change-application-feeSimulation .img-logo,
.quocte-make .img-logo {
  max-width: 150px;
}

.time-fee .ant-select-selector {
  height: 20px !important;
  border-color: #000 !important;
}

.usage-inquiry .ant-form-item-control {
  height: 20px;
}

.time-fee .ant-select-selection-item {
  line-height: 20px !important;
}

.table-config td,
.table-note td {
  border: 1px solid;
}

.vertical-align-top {
  vertical-align: top;
}

.table-config,
.table-note {
  border: 2px solid;
}

.usage-inquiry .usage-plan .title-sub {
  max-width: 38.5%;
}

.block-note {
  margin-left: 30px;
}


.horizontal-center {
  justify-content: center;
}

.table-inner {
  padding: 10px 30px;
}

.col-value {
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .img-logo {
    max-width: 100%;
  }

  .header {
    padding: 5px 5px;
  }

  .header .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 5px;
  }

  .img-user-thumbail {
    max-width: 26px;
  }

  .header .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .box,
  .container-custom-big {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .header .ant-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container-custom {
    margin: 10px;

  }

  .m-text-center {
    text-align: center;
  }

  .box-register .box-body {
    padding: 10px;
  }

  .btn-link {
    font-size: 9px;
    padding: 0 2px;
  }

  .f13-xs {
    font-size: 13px;
  }

  .title-profile {
    padding: 10px 10px;
  }
}

@media only screen and (min-width: 768px) {
  .img-logo {
    max-width: 300px;
  }

  .btn-primary {
    min-width: 100px;
  }

  .base-infomation .ant-form-item-label {
    min-width: 185px;
  }

  .usage-plan .store input,
  .traffic input {
    text-align: center;
  }

  .list-row-server .store .ant-input-number {
    width: 70px;
  }

  .txtCampaignCode,
  .txtPrimary {
    max-width: 134px;
    height: 20px;
  }

  .txtCampaignCode input,
  .txtPrimary input {
    height: auto !important;
  }

  .box-dayofweek:not(.scr-confirm) {
    padding-left: 50px;
  }

  .box-dayofweek .scr-confirm {
    padding-left: 35px;
  }

  .list-server,
  .row-option,
  .user-time-body {
    padding-left: 70px;
  }

  .day-of-week {
    width: 75px;
    display: block;
  }

  .txt-campain {
    max-width: 134px;
  }

  .modal-delete button {
    width: 100px;
    height: 20px;
    line-height: 20px;
    padding: 0;
    border-radius: 3px;
    border: 1px solid;
  }

  .plan-detail .col-label-lv1 {
    max-width: 172px;
  }

  .col-date-value {
    width: 90px;
  }

  .td-spec {
    min-width: 170px;
  }

  .td-multiply {
    width: 50px;
    text-align: center;
  }

  .free-simulation .day-of-week,
  .plan-detail .day-of-week {
    display: block;
  }

  .suspension-input .col-label-lv1 {
    width: 158px !important;
  }

  .suspension-confirm .col-label-lv1 {
    width: 158px;
  }

  .table-stop .col-label-lv1 {
    width: 175px;
  }

  .box-plan-info .col-left-lbl {
    flex-basis: 44%;
  }

  .block-setting-start-date .col-left-lbl {
    flex-basis: 20%;
  }

  .application-confirm .col-left-lbl {
    flex-basis: 34%;
  }

  .option-change-input .col-left-lbl {
    flex-basis: 39%;
  }

  .change-system-usage-iime-confirm .col-left-lbl {
    flex-basis: 39%;
  }

  .campaign-code-add-input .col-left-lbl,
  .campaign-code-add-confirm .col-left-lbl {
    flex-basis: 39%;
  }

  .plan-change-application-confirm .col-left-lbl {
    flex-basis: 44%;
  }

  .change-system-usage-time .col-left-lbl {
    flex-basis: 39%;
  }

  .option-change-confirm .col-left-lbl {
    flex-basis: 39%;
  }

  .suspension-confirm .col-left-lbl {
    flex-basis: 37%;
  }

  .suspension-confirm .title-sub,
  .suspension-input .title-sub {
    width: 43%;
  }

  .cancellation-reservation-input .title-sub,
  .cancellation-reservation-confirm .title-sub {
    width: 47%;
  }

  .administrator-confirm .title-sub {
    width: 34.5%;
  }

  .administrator-add .title-sub {
    width: 42.5%;
  }

  .campaign-code-added-input .title-sub {
    width: 42.9%;
  }

  .plan-input .campaign .title-sub {
    width: 17.5%;
  }

  .campaign-code-added-input .col-left-lbl {
    flex-basis: 39%;
  }

  .campaign-code-added-confirm .col-left-lbl {
    flex-basis: 39.5%;
  }

  .campaign-code-added-confirm .usage-plan .title-sub {
    width: 43%;
  }

  .plan-detail .base-information .col-left-lbl,
  .plan-detail .version-block .col-left-lbl {
    width: 40%;
  }

  /*page usage inquiry*/
  .col-number {
    padding-left: 30px;
  }

  .col-note {
    padding-left: 30px;
  }

  .table-config {
    margin-left: 30px;
  }

  .usage-inquiry .col-left-lbl {
    width: 30%;
  }
}

.footer-home {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
}

.btn-cardinfo-change {
  white-space: break-spaces;
  height: auto;
  margin-bottom: 5px;
}

.div-value {
  width: 85%;
}

.div-unit {
  width: 15%;
}

.alert-terms {
  font-size: 11px;
}

.btn-action {
  right: -60px;
  border-radius: 3px;
}

.client-information .btn-action {
  top: 0;
}

.row-item-primary {
  margin-left: 15px;
  border-left: 1px solid;
}

.col-lbl1 {
  max-width: 23.9%;
}

.row-item-primary {
  position: relative;
}

.row-item-primary::after {
  display: block;
  content: '';
  height: 100%;
  background-color: rgb(204, 255, 255);
  position: absolute;
  width: 15px;
  left: -16px;
  top: -1px;
}

.item-primary {
  flex-grow: 1;
}

.mypage {
  background-color: rgb(248, 203, 173);
}

.common-modal .ant-modal-header {
  background-color: rgb(204, 255, 255);
}

.common-modal .ant-modal-footer {
  border-top: 0;
}

.tbl-lesson td {
  padding: 3px;
}

.article img {
  max-width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 110px;
}

.article .btn-view-file {
  background-color: #d6ebe7;
  height: 22px;
  border-radius: 5px;
  padding: 0 20px;
  color: #3333ff;
  border: none;
}

.article .btn-video-file {
  background-color: #ffd6ff;
  color: #f00;
  border: none;
  height: 22px;
  border-radius: 5px;
  padding: 0 20px;
}

.article {
  border-radius: 10px;
  margin-bottom: 10px;
}

.card-1 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

span.circle-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffc000;
  border-radius: 50%;
  margin-right: 5px;
}

.align-items-baseline {
  align-items: baseline;
}

.overflow-x-auto {
  overflow-x: auto;
}

.f14 {
  font-size: 14px !important;
}

.tag-new {
  color: #ff353b;
  padding: 2px 20px;
  border-radius: 5px;
  min-height: 24px;
}

.article-title {
  min-height: 44px;
  max-height: 44px;
  overflow: hidden;
  white-space: break-spaces;
}

.customize-col .article {
  width: 220px;
}

.article-avatar {
  min-height: 110px;
  overflow-y: hidden;
}

.training-delete {
  display: none;
}

.training-row {
  position: relative;
}

.training-row:hover .training-delete {
  display: block;
  position: absolute;
  right: 10px;
  top: 50px;
  cursor: pointer;
  border: 0px;
  box-shadow: none;
}

.ant-upload-select-picture-card-error>div {
  border-color: red !important;
}

.tableFixHead {
  overflow: auto;
  max-height: calc(100vh - 400px);
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableFixHead table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 0px;
}

.ant-form-item-has-error .input-mask {
  background-color: #fff;
  border-color: #ff4d4f !important;
}

.number-input .ant-input-number-input {
  height: 30px;

}

.security-item {
  display: flex;
  height: 30px;
  justify-items: center;
  align-items: center;
}

.rdo-group-backup {
  font-size: inherit;
  display: block;
}

.ant-form-item-has-error .rdo-group-backup .ant-radio-inner {
  border-color: #ff4d4f !important;
}


.config-form .ant-form-item-explain {
  display: none;
}

.config-form .mb-secondary {
  position: relative;
}

.config-form .ant-form-item-control:has(.show-message) {
  position: unset !important;
  ;
}



.config-form .ant-form-item-explain .show-message {
  position: absolute !important;
}

.config-form .ant-form-item-explain:has(.show-message) {
  display: flex;
}


/* .show-message {
  position: absolute;
} */

.btn-action-oci {
  border-radius: 5px;
  line-height: 10px;
  font-size: 10px;
  padding: 0 8px;
}

.btn-action-oci[disabled] {
  background-color: #BFBFBF !important;
  color: #fff;
  line-height: 10px;
  font-size: 10px;
  border-color: #BFBFBF !important;
}

.btn-action-oci:hover {
  background-color: #fff !important;
  border-color: #40a9ff !important;
  color: #40a9ff !important;
}

.btn-action-oci-stop {
  background-color: #FF0000 !important;
  border-color: #FF0000 !important;
}

.btn-action-oci-start {
  background-color: #00B050 !important;
  border-color: #00B050 !important;
}

.btn-action-oci-restart {
  background-color: #F79646 !important;
  border-color: #F79646 !important;
}

.btn-action-upd-status {
  background-color: #959393 !important;
  border-color: #959393 !important;
}